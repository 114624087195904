import './ColorizedMetricValueCell.scss';

import {RowNode} from 'ag-grid-community';
import classNames from 'classnames';
import React, {useMemo} from 'react';

import {MetricCellValue, TypedCellRendererParams} from 'toolkit/ag-grid/types';
import {getDefaultDecimalPlaces} from 'toolkit/format/format';
import {getMetricDisplayType} from 'toolkit/metrics/utils';
import {
  adjustLuminanceByFactor,
  DARK_COLORS,
  LIGHTEST_COLORS,
  TABLE_COLUMN_COLORS,
  TABLE_COLUMN_TEXT_COLORS,
} from 'toolkit/utils/colors';
import * as Types from 'types';
import {isNullish} from 'utils/functions';
import {MetricCellParams} from 'widgets/tables/impl/types';

const getValueForRuleComparison = (
  metricInstanceConfig: Types.MetricInstanceConfig,
  value: number | null | undefined
): number | null => {
  if (isNullish(value) || !Number.isFinite(value)) {
    return null;
  }
  const displayType = getMetricDisplayType(metricInstanceConfig.metricInstance);
  return parseFloat(
    value.toFixed(
      getDefaultDecimalPlaces(displayType, value) +
        // Percent value 12.3% is stored as 0.123
        (displayType === Types.MetricDisplayType.PERCENT ? 2 : 0)
    )
  );
};

function getRange(
  metricInstanceConfig: Types.MetricInstanceConfig,
  value: MetricCellValue
): Types.Range | null {
  if (metricInstanceConfig.formattingRules) {
    const displayedValue = getValueForRuleComparison(metricInstanceConfig, value.value);
    if (isNullish(displayedValue) || !Number.isFinite(displayedValue)) {
      return null;
    }
    return (
      metricInstanceConfig.formattingRules.ranges.find(
        range =>
          (isNullish(range.lowerBound) || displayedValue >= range.lowerBound) &&
          (isNullish(range.upperBound) || displayedValue <= range.upperBound)
      ) ?? null
    );
  }
  return null;
}

const MetricValueLabel: React.FC<{color: Types.Color; label: string}> = ({color, label}) => {
  const cssBackgroundColor = LIGHTEST_COLORS.get(color)!;
  const cssColor = DARK_COLORS.get(color)!;
  return (
    <div
      className="MetricValueLabel"
      style={{
        backgroundColor: cssBackgroundColor,
        color: cssColor,
        borderColor: cssColor,
      }}
    >
      {label}
    </div>
  );
};

function getFormatColor(
  metricInstanceConfig: Types.MetricInstanceConfig,
  range: Types.Range | null,
  renderType: Types.ConditionalMetricFormatRenderType
): Types.Color | null | undefined {
  return metricInstanceConfig.formattingRules?.renderType === renderType ? range?.color : null;
}

function getBackgroundColorStyleProps(color: Types.Color | null | undefined) {
  if (isNullish(color)) {
    return {};
  }
  return {
    style: {
      backgroundColor: TABLE_COLUMN_COLORS.get(color),
      borderColor: adjustLuminanceByFactor(TABLE_COLUMN_COLORS.get(color)!, 0.75),
    },
  };
}

function getTextColorStyleProps(color: Types.Color | null | undefined) {
  if (isNullish(color)) {
    return {};
  }
  return {style: {color: TABLE_COLUMN_TEXT_COLORS.get(color)}};
}

const ColorizedMetricValueCell: React.FC<
  ColorizedMetricValueCellParams & {children: React.ReactNode}
> = ({metricInstanceConfig, value, children, node, isTotalColumn}) => {
  const isTotal = node?.rowPinned === 'bottom' || isTotalColumn;
  const range = useMemo(
    () => (isTotal ? null : getRange(metricInstanceConfig, value)),
    [metricInstanceConfig, value, isTotal]
  );
  const backgroundColor = useMemo(
    () =>
      getFormatColor(
        metricInstanceConfig,
        range,
        Types.ConditionalMetricFormatRenderType.BACKGROUND_COLOR
      ) ?? metricInstanceConfig.customColor,
    [metricInstanceConfig, range]
  );
  const textColor = useMemo(
    () =>
      getFormatColor(
        metricInstanceConfig,
        range,
        Types.ConditionalMetricFormatRenderType.TEXT_COLOR
      ),
    [metricInstanceConfig, range]
  );
  const labelColor = useMemo(
    () =>
      getFormatColor(metricInstanceConfig, range, Types.ConditionalMetricFormatRenderType.LABEL),
    [metricInstanceConfig, range]
  );

  const cellContent = useMemo(() => {
    if (
      metricInstanceConfig.formattingRules?.renderType ===
      Types.ConditionalMetricFormatRenderType.LABEL
    ) {
      return (
        range &&
        range.name &&
        labelColor && (
          <div className="MetricValueLabelContainer">
            <MetricValueLabel color={labelColor} label={range.name} />
          </div>
        )
      );
    }
    return (
      <div className="metric-value-content">
        <div className="metric-value" {...getTextColorStyleProps(textColor)}>
          {children}
        </div>
      </div>
    );
  }, [range, labelColor, textColor, children, metricInstanceConfig.formattingRules?.renderType]);

  return (
    <div
      className={classNames('ColorizedMetricValueCell', {
        colorized: !!backgroundColor,
      })}
      {...getBackgroundColorStyleProps(backgroundColor)}
    >
      {cellContent}
    </div>
  );
};
ColorizedMetricValueCell.displayName = 'ColorizedMetricValueCell';

const ColorizedMetricValueCellFramework = <T,>({
  valueFormatted,
  ...otherProps
}: TypedCellRendererParams<T> & ColorizedMetricValueCellParams) => (
  <ColorizedMetricValueCell {...otherProps}>{valueFormatted}</ColorizedMetricValueCell>
);
ColorizedMetricValueCellFramework.displayName = 'ColorizedMetricValueCellFramework';

type ColorizedMetricValueCellParams = MetricCellParams & {
  value: MetricCellValue;
  node: RowNode;
  isTotalColumn?: boolean;
};

export function withColorizedMetricValueCell<P extends ColorizedMetricValueCellParams>(
  WrappedCell: React.ComponentType<P>
) {
  const Cell: React.FC<P> = props => (
    <ColorizedMetricValueCell {...props}>
      <WrappedCell {...props} />
    </ColorizedMetricValueCell>
  );

  return Cell;
}

export default ColorizedMetricValueCellFramework;
