import {List} from 'immutable';

import {CurrentUser} from 'redux/reducers/user';
import {isAlloyUser, isAtLeast, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import * as Types from 'types';

function isSuperAdmin(currentUser: CurrentUser): boolean {
  return isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN);
}

function isCurrentUserAlloyUser(currentUser: CurrentUser): boolean {
  return isAlloyUser(currentUser.user.email);
}

export function getGlobalHotKeyDefinitions(
  currentUser: CurrentUser,
  allowedVendors: List<Types.Vendor>,
  handler?: GlobalHotKeyHandler
): readonly HotKeyDefinition[] {
  const allDefinitions: readonly HotKeyDefinition[] = [
    {
      name: 'topBarSearch',
      keyMaps: ['s', '/'],
      handler: handler?.handleSearchShortcut,
      description: 'Focus Search',
    },
    {
      name: 'vendorSearch',
      keyMaps: ['u'],
      handler: handler?.handleVendorSearchShortcut,
      description: 'Open User Menu',
    },
    {
      name: 'createDashboard',
      keyMaps: ['c d'],
      handler: handler?.handleCreateDashboard,
      description: 'Create Dashboard',
    },
    {
      name: 'openDataStatus',
      keyMaps: ['d s', 'D S'],
      handler: handler?.handleDataStatusShortcut,
      description: 'Open Data Status*',
    },
    {
      name: 'openDataImport',
      keyMaps: ['d i', 'D I'],
      handler: handler?.handleDataImportShortcut,
      description: 'Open Data Import*',
    },
    {
      name: 'openDataSubscriptions',
      keyMaps: ['d u', 'D U'],
      handler: handler?.handleDataSubscriptionsShortcut,
      description: 'Open Data Subscriptions*',
    },
    {
      name: 'openDataPendingEmails',
      keyMaps: ['d e', 'D E'],
      handler: handler?.handleDataForwardedFilesShortcut,
      description: 'Open Pending Emails*',
    },
    {
      name: 'openDataFiles',
      keyMaps: ['d f', 'D F'],
      handler: handler?.handleFilesShortcut,
      description: 'Open Data Files*',
    },
    {
      name: 'openProducts',
      keyMaps: ['p', 'd p', 'P', 'D P'],
      handler: handler?.handleDataProductsShortcut,
      description: 'Open Products*',
    },
    {
      name: 'openLocations',
      keyMaps: ['d l', 'D L'],
      handler: handler?.handleDataLocationsShortcut,
      description: 'Open Locations*',
    },
    {
      name: 'goToHomePage',
      keyMaps: ['g h', 'G H'],
      handler: handler?.handleGoToHomepage,
      description: 'Go to homepage*',
    },
    {
      name: 'goToFeatures',
      keyMaps: ['g f'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleGoToFeatures,
      description: 'Go to feature flag override settings',
    },
    {
      name: 'openSettings',
      keyMaps: ['g s', 'G S'],
      handler: handler?.handleSettingsShortcut,
      description: 'Open Settings*',
    },
    {
      name: 'openSettingsFiles',
      keyMaps: ['f f', 'F F'],
      hasPermission: isSuperAdmin,
      handler: handler?.handleSettingsFilesShortcut,
      description: 'Open Files (Alloy-internal view)*',
    },
    {
      name: 'goToAlloyPublic',
      keyMaps: ['g p', 'G P'],
      hasPermission: _ => !!allowedVendors.find(vendor => vendor.id === PUBLIC_VENDOR_ID),
      handler: handler?.handleGoToAlloyPublic,
      description: 'Go to Alloy Public*',
    },
    {
      name: 'goToAlloyPublicJobs',
      keyMaps: ['g j', 'G J'],
      hasPermission: _ => !!allowedVendors.find(vendor => vendor.id === PUBLIC_VENDOR_ID),
      handler: handler?.handleGoToAlloyPublicJobs,
      description: 'Go to Alloy Public Jobs*',
    },
    {
      name: 'goToAlloyPublicJobLocks',
      keyMaps: ['g l', 'G L'],
      hasPermission: _ => !!allowedVendors.find(vendor => vendor.id === PUBLIC_VENDOR_ID),
      handler: handler?.handleGoToAlloyPublicJobLocks,
      description: 'Go to Alloy Public Job Locks*',
    },
    {
      name: 'goToAlloyPublicFiletypes',
      keyMaps: ['g f t', 'G F T'],
      hasPermission: _ => !!allowedVendors.find(vendor => vendor.id === PUBLIC_VENDOR_ID),
      handler: handler?.handleGoToAlloyPublicFiletypes,
      description: 'Go to Alloy Public Filetypes*',
    },
    {
      name: 'goToPreviousVendor',
      keyMaps: ['g v', 'G V'],
      hasPermission: _ => allowedVendors.size > 1,
      handler: handler?.handlePreviousVendorShortcut,
      description: 'Go to previous vendor (from using hotkeys)*',
    },
    {
      name: 'bookmarkPage',
      keyMaps: ['\\'],
      handler: handler?.handleSetBookmark,
      description: 'Bookmark a page',
    },
    {
      name: 'goToBookmark',
      keyMaps: ['option+\\', 'shift+option+\\'],
      handler: handler?.handleGoToBookmark,
      description: 'Go to your bookmarked page*',
    },
    {
      name: 'openCreateMenu',
      keyMaps: ['g c'],
      handler: handler?.handleGlobalCreateShortcut,
      description: 'Open Create Menu',
    },
    {
      name: 'shortcutHelp',
      keyMaps: ['?'],
      handler: handler?.openShortcutHelp,
      description: 'Show this help dialog',
    },
    {
      name: 'openFeeds',
      keyMaps: ['f s', 'F S'],
      hasPermission: isSuperAdmin,
      handler: handler?.handleFeedsShortcut,
      description: 'Open Feeds*',
    },
    {
      name: 'openMetricSnapshots',
      keyMaps: ['g m s', 'G M S'],
      hasPermission: isSuperAdmin,
      handler: handler?.handleMetricSnapshotsShortcut,
      description: 'Open Metric Snapshots*',
    },
    {
      name: 'presentationMode',
      keyMaps: ['m p'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handlePresentationMode,
      description: 'Presentation mode',
    },
    {
      name: 'cacheInspector',
      keyMaps: ['m c'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleToggleCacheInspector,
      description: 'Cache inspector',
    },
    {
      name: 'impersonateUser',
      keyMaps: ['m i u'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleImpersonateUser,
      description: 'Impersonate as USER',
    },
    {
      name: 'impersonateAdmin',
      keyMaps: ['m i a'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleImpersonateAdmin,
      description: 'Impersonate as ADMIN',
    },
    {
      name: 'impersonateSuperAdmin',
      keyMaps: ['m i s'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleImpersonateSuperAdmin,
      isEnabledWithModals: true,
      description: 'Impersonate as SUPER ADMIN',
    },
    {
      name: 'developmentMode',
      keyMaps: ['m i l'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleDevelopmentMode,
      isEnabledWithModals: true,
      description: 'Development mode',
    },
    {
      name: 'manualUpdateMode',
      keyMaps: ['m m u'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleManualUpdateMode,
      isEnabledWithModals: true,
      description: 'Manual update mode',
    },
    {
      name: 'goToLocal',
      keyMaps: ['ctrl+option+l'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleGoToLocal,
      isEnabledWithModals: true,
      description: 'Go to local (same URL)',
    },
    {
      name: 'goToStaging',
      keyMaps: ['ctrl+option+s'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleGoToStaging,
      isEnabledWithModals: true,
      description: 'Go to staging (same URL)',
    },
    {
      name: 'goToProduction',
      keyMaps: ['ctrl+option+p'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleGoToProduction,
      isEnabledWithModals: true,
      description: 'Go to production (same URL)',
    },
    {
      name: 'toggleComputeCache',
      keyMaps: ['c c t'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleToggleComputeCache,
      isEnabledWithModals: true,
      description: 'Temporarily disable compute cache',
    },
    {
      name: 'goToPreviousVendor',
      keyMaps: ['option+left'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleGoToPreviousVendor,
      isEnabledWithModals: false,
      description: 'Go to the previous active vendor',
    },
    {
      name: 'goToNextVendor',
      keyMaps: ['option+right'],
      hasPermission: isCurrentUserAlloyUser,
      handler: handler?.handleGoToNextVendor,
      isEnabledWithModals: false,
      description: 'Go to the next active vendor',
    },
    {
      name: 'toggleSidebar',
      keyMaps: ['meta+b'],
      handler: handler?.handleToggleSidebar,
      isEnabledWithModals: false,
      description: 'Toggle Settings sidebar',
    },
  ];
  return allDefinitions.filter(({hasPermission}) => !hasPermission || hasPermission(currentUser));
}

export function getAnalysisHotKeyDefinitions(
  handler?: AnalysisHotKeyHandler
): readonly HotKeyDefinition[] {
  return [
    {
      name: 'focusViewMenu',
      keyMaps: ['v'],
      handler: handler?.handleFocusViewMenuShortcut,
      description: 'Focus View Menu',
    },
    {
      name: 'setTimeLastWeek',
      keyMaps: ['w'],
      handler: handler?.handleSetTimeLastWeekShortcut,
      description: 'Set Time: Last Week',
    },
    {
      name: 'setTimeLastMonth',
      keyMaps: ['o'],
      handler: handler?.handleSetTimeLastMonthShortcut,
      description: 'Set Time: Last Month',
    },
    {
      name: 'setTimeYearToDate',
      keyMaps: ['y'],
      handler: handler?.handleSetTimeYearToDateShortcut,
      description: 'Set Time: Year to Date',
    },
    {
      name: 'focusPeriodChooser',
      keyMaps: ['t'],
      handler: handler?.handleFocusPeriodChooserShortcut,
      description: 'Focus Time Period Chooser',
    },
    {
      name: 'toggleEditMode',
      keyMaps: ['e'],
      handler: handler?.handleEditModeShortcut,
      description: 'Toggle layout editing mode (grid layouts)',
    },
  ];
}

type HotKeyHandler = (event: KeyboardEvent) => void;

export interface HotKeyDefinition {
  readonly description: string;
  readonly handler?: HotKeyHandler;
  readonly hasPermission?: (currentUser: CurrentUser) => boolean;
  readonly isEnabledWithModals?: boolean;
  readonly keyMaps: readonly string[];
  readonly name: string;
}

export interface GlobalHotKeyHandler {
  handleCreateDashboard: HotKeyHandler;
  handleDevelopmentMode: HotKeyHandler;
  handleGoToAlloyPublic: HotKeyHandler;
  handleGoToAlloyPublicJobs: HotKeyHandler;
  handleGoToAlloyPublicJobLocks: HotKeyHandler;
  handleGoToAlloyPublicFiletypes: HotKeyHandler;
  handleGoToLocal: HotKeyHandler;
  handleGoToStaging: HotKeyHandler;
  handleGoToProduction: HotKeyHandler;
  handleImpersonateSuperAdmin: HotKeyHandler;
  handleImpersonateAdmin: HotKeyHandler;
  handleImpersonateUser: HotKeyHandler;
  handleManualUpdateMode: HotKeyHandler;
  handleSearchShortcut: HotKeyHandler;
  handleDataStatusShortcut: HotKeyHandler;
  handleDataSubscriptionsShortcut: HotKeyHandler;
  handleDataForwardedFilesShortcut: HotKeyHandler;
  handleDataImportShortcut: HotKeyHandler;
  handleDataProductsShortcut: HotKeyHandler;
  handleDataLocationsShortcut: HotKeyHandler;
  handleSettingsShortcut: HotKeyHandler;
  handleSettingsFilesShortcut: HotKeyHandler;
  handleFilesShortcut: HotKeyHandler;
  handleFeedsShortcut: HotKeyHandler;
  handleMetricSnapshotsShortcut: HotKeyHandler;
  handlePresentationMode: HotKeyHandler;
  handleToggleCacheInspector: HotKeyHandler;
  openShortcutHelp: HotKeyHandler;
  handleVendorSearchShortcut: HotKeyHandler;
  handleGlobalCreateShortcut: HotKeyHandler;
  handlePreviousVendorShortcut: HotKeyHandler;
  handleSetBookmark: HotKeyHandler;
  handleGoToBookmark: HotKeyHandler;
  handleGoToHomepage: HotKeyHandler;
  handleGoToFeatures: HotKeyHandler;
  handleToggleComputeCache: HotKeyHandler;
  handleGoToPreviousVendor: HotKeyHandler;
  handleGoToNextVendor: HotKeyHandler;
  handleToggleSidebar: HotKeyHandler;
}

export interface AnalysisHotKeyHandler {
  handleFocusPeriodChooserShortcut: HotKeyHandler;
  handleFocusViewMenuShortcut: HotKeyHandler;
  handleSetTimeLastMonthShortcut: HotKeyHandler;
  handleSetTimeLastWeekShortcut: HotKeyHandler;
  handleSetTimeYearToDateShortcut: HotKeyHandler;
  handleEditModeShortcut: HotKeyHandler;
}
