import {push} from 'connected-react-router';
import {List, Map, Set} from 'immutable';

import * as Api from 'api';
import {PresentationFile} from 'app/presentation/types';
import {Dispatch, GetState} from 'redux/reducers';
import {FeatureFlag} from 'toolkit/feature-flags/types';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {configureSentryUser} from 'utils/exceptions';

import ActionType from './types';

export type CurrentUserAction =
  | {type: ActionType.SetUser; user: Types.User}
  | {type: ActionType.SetUserInfo; userInfo: Types.UserInfo}
  | {type: ActionType.ResetReduxStore}
  | {type: ActionType.SetAllowedVendors; vendors: List<Types.Vendor>}
  | {type: ActionType.SetCurrentVendor; vendor: Types.Vendor}
  | {type: ActionType.SetDevelopmentMode; isDevelopmentMode: boolean}
  | {type: ActionType.SetPresentationMode}
  | {type: ActionType.SetPermissions; permissionKeys: readonly string[]}
  | {type: ActionType.SetPermissionsOverrideDialogOpen; isPermissionsOverrideDialogOpen: boolean}
  | {type: ActionType.SetManualViewUpdateMode; isManualViewUpdateMode: boolean}
  | {type: ActionType.SetPresentationFile; file: PresentationFile}
  | {type: ActionType.SetSavedFeatureFlags; allFeaturesByUser: Map<number, Set<FeatureFlag>>}
  | {type: ActionType.SetUserFeatureFlags; featureFlags: Set<FeatureFlag>}
  | {type: ActionType.SetUserRole; role: Types.Role}
  | {type: ActionType.SetVendorAnalysisSettings; analysisSettings: Types.VendorAnalysisSettings}
  | {type: ActionType.SetSettingsSidebarOpen; isSettingsSidebarOpen: boolean};

export interface ICurrentUserActions {
  resetReduxStore: () => any;
  logout: () => any;
  logoutWithExpiredCredentials: () => any;
  setDevelopmentMode: (isDevMode: boolean) => any;
  setPermissions: (permissionKeys: readonly string[]) => any;
  setPermissionsOverrideDialogOpen: (isPermissionsOverrideDialogOpen: boolean) => any;
  setManualViewUpdateMode: (isManualViewUpdateMode: boolean) => any;
  setPresentationMode: () => any;
  setPresentationFile: (file: PresentationFile) => any;
  setUserFeatureFlags: (featureFlags: Set<FeatureFlag>) => any;
  setUserRole: (role: Types.Role) => any;
  setUser: (user: Types.User) => any;
  setUserInfo: (userInfo: Types.UserInfo) => any;
  setVendor: (vendor: Types.Vendor) => any;
  setVendorAnalysisSettings: (analysisSettings: Types.VendorAnalysisSettings) => any;
  setSettingsSidebarOpen: (isSettingsSidebarOpen: boolean) => any;
}

function setVendor(vendor: Types.Vendor) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SetCurrentVendor,
      vendor,
    });
  };
}

export const CurrentUserActions: ICurrentUserActions = {
  resetReduxStore: () => {
    return {
      type: ActionType.ResetReduxStore,
    };
  },

  setDevelopmentMode: (isDevelopmentMode: boolean) => {
    return {
      isDevelopmentMode,
      type: ActionType.SetDevelopmentMode,
    };
  },

  setPermissionsOverrideDialogOpen: (isPermissionsOverrideDialogOpen: boolean) => {
    return {
      isPermissionsOverrideDialogOpen,
      type: ActionType.SetPermissionsOverrideDialogOpen,
    };
  },

  setManualViewUpdateMode: (isManualViewUpdateMode: boolean) => {
    return {
      isManualViewUpdateMode,
      type: ActionType.SetManualViewUpdateMode,
    };
  },

  setPresentationMode: () => {
    return {
      type: ActionType.SetPresentationMode,
    };
  },

  setPresentationFile: (file: PresentationFile) => {
    return {
      file,
      type: ActionType.SetPresentationFile,
    };
  },

  logoutWithExpiredCredentials: () => {
    return (dispatch: Dispatch) => {
      dispatch({
        redirectUrl: window.location.href,
        type: ActionType.SetLoginRedirectUrl,
      });
      dispatch(push('/login?expired=true'));
    };
  },

  logout: () => {
    configureSentryUser(null);
    return {
      promise: Api.Authentication.logout(),
      type: ActionType.ResetReduxStore,
    };
  },

  setUserFeatureFlags: (featureFlags: Set<FeatureFlag>) => {
    return {
      featureFlags,
      type: ActionType.SetUserFeatureFlags,
    };
  },

  setUser: (user: Types.User) => {
    return (dispatch: Dispatch, getState: GetState) => {
      const vendor = assertTruthy(
        getState().userData.allowedVendors.find(vendor => vendor.id === user.vendorId)
      );
      dispatch({type: ActionType.SetUser, user});
      dispatch(setVendor(vendor));
    };
  },

  setUserInfo: (userInfo: Types.UserInfo) => {
    return {
      type: ActionType.SetUserInfo,
      userInfo,
    };
  },

  setUserRole: (role: Types.Role) => {
    return {
      role,
      type: ActionType.SetUserRole,
    };
  },

  setPermissions: (permissions: readonly string[]) => {
    return {
      type: ActionType.SetPermissions,
      permissionKeys: permissions,
    };
  },

  setVendor,

  setVendorAnalysisSettings: (analysisSettings: Types.VendorAnalysisSettings) => {
    return {
      analysisSettings,
      type: ActionType.SetVendorAnalysisSettings,
    };
  },

  setSettingsSidebarOpen: (isSettingsSidebarOpen: boolean) => {
    return {
      isSettingsSidebarOpen,
      type: ActionType.SetSettingsSidebarOpen,
    };
  },
};
