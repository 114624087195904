import {push} from 'connected-react-router';
import * as History from 'history';
import React, {useCallback, useState} from 'react';

import * as Api from 'api';
import {promptUserToReload, showAlert, showErrorAlert} from 'app/alerts';
import {useDashboardTransferClient} from 'dashboard/tab-transfer/hooks';
import {getNewVendorPathname} from 'nav/utils';
import {toggleCacheInspectorVisible} from 'redux/actions/ajax';
import {activatePresentationMode, storeAndGoToNewView} from 'redux/actions/analysis';
import {setGlobalCreateMenuOpen, setUserMenuOpen} from 'redux/actions/navigation';
import {CurrentUserActions} from 'redux/actions/user';
import {UserDataActions} from 'redux/actions/user-data';
import {useCurrentUser} from 'redux/context/user';
import {Dispatch} from 'redux/reducers';
import {CurrentUser} from 'redux/reducers/user';
import {getAllMetricsWithAnyData} from 'redux/selectors/analysis';
import useSelector from 'redux/selectors/useSelector';
import useDispatch from 'redux/useDispatch';
import ModalDialog from 'toolkit/components/ModalDialog';
import {toggleComputeCacheGlobally} from 'toolkit/compute/api';
import {isAtLeast, PUBLIC_VENDOR_ID} from 'toolkit/users/utils';
import {createDefaultView, getHomeUrl} from 'toolkit/views/utils';
import * as Types from 'types';
import {assertTruthy} from 'utils/assert';
import {useApi, useResult} from 'utils/useApi';

import {getGlobalHotKeyDefinitions, GlobalHotKeyHandler} from './HotKeyDefinitions';
import HotKeys from './HotKeys';
import ShortcutHelp from './ShortcutHelp';

export function changePermission(role: Types.Role, currentUser: CurrentUser, dispatch: Dispatch) {
  Api.Authentication.saveUser({...currentUser.user, role}, {vendorId: PUBLIC_VENDOR_ID})
    .then(user => {
      dispatch(UserDataActions.setSavedUser(user));
      dispatch(CurrentUserActions.setUser(user));
      promptUserToReload(
        `Your permissions have been changed to '${role}'. Please reload the page for the settings to take full effect.`
      );
    })
    .catch(showErrorAlert);
}

const GlobalHotKeys: React.FC<Props> = ({location, onFocusSearchRequested}) => {
  const [isShortcutHelpOpen, setIsShortcutHelpOpen] = useState<boolean>(false);
  const allowedVendors = useSelector(state => state.userData.allowedVendors);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  useDashboardTransferClient();

  const openRedirect = useCallback(
    (event: KeyboardEvent, url: string) => {
      if (event.shiftKey) {
        window.open(`${window.location.origin}${url}`);
      } else {
        if (url.includes(currentUser.vendor.name)) {
          dispatch(push(url));
        } else {
          window.location.href = url;
        }
      }
    },
    [currentUser.vendor, dispatch]
  );

  const openNewEnv = useCallback((newEnvDomain: string) => {
    window.open(window.location.href.replace(window.location.host, newEnvDomain));
  }, []);

  const handleGlobalCreateShortcut = useCallback(() => {
    dispatch(setGlobalCreateMenuOpen(true));
  }, [dispatch]);

  const handleDataPageShortcut = useCallback(
    (targetTab: string) => (event: KeyboardEvent) =>
      openRedirect(event, `/${currentUser.vendor.name}/data/${targetTab}`),
    [openRedirect, currentUser.vendor]
  );

  const handleDataStatusShortcut = handleDataPageShortcut('status');
  const handleDataSubscriptionsShortcut = handleDataPageShortcut('subscriptions');
  const handleDataForwardedFilesShortcut = handleDataPageShortcut('forwarded-files');

  const handleDataProductsShortcut = handleDataPageShortcut('products');
  const handleDataLocationsShortcut = handleDataPageShortcut('locations');
  const handleDataImportShortcut = handleDataPageShortcut('import');
  const handleFilesShortcut = handleDataPageShortcut('files');

  const handleSettingsShortcut = useCallback(
    (event: KeyboardEvent) => openRedirect(event, `/${currentUser.vendor.name}/settings`),
    [currentUser.vendor, openRedirect]
  );

  const handleFeedsShortcut = useCallback(
    (event: KeyboardEvent) => {
      if (isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN)) {
        openRedirect(event, `/${currentUser.vendor.name}/settings/feeds`);
      }
    },
    [currentUser, openRedirect]
  );

  const handleSettingsFilesShortcut = useCallback(
    (event: KeyboardEvent) =>
      openRedirect(event, `/${currentUser.vendor.name}/settings/data-files`),
    [currentUser.vendor, openRedirect]
  );

  const handleMetricSnapshotsShortcut = useCallback(
    (event: KeyboardEvent) => {
      if (isAtLeast(currentUser.user.role, Types.Role.SUPER_ADMIN)) {
        openRedirect(event, `/${currentUser.vendor.name}/settings/metric-snapshots`);
      }
    },
    [currentUser, openRedirect]
  );

  const handleSearchShortcut = useCallback(
    () => onFocusSearchRequested(),
    [onFocusSearchRequested]
  );

  const handlePreviousVendorShortcut = useCallback(
    (event: KeyboardEvent) => {
      const prevVendor = localStorage.getItem('previousVendor');
      localStorage.setItem('previousVendor', currentUser.vendor.name);
      openRedirect(
        event,
        location.pathname.replace(currentUser.vendor.name, prevVendor ?? currentUser.vendor.name)
      );
    },
    [location, currentUser.vendor, openRedirect]
  );

  const handleGoToHomepage = useCallback(
    (event: KeyboardEvent) => openRedirect(event, getHomeUrl(currentUser.vendor.name)),
    [openRedirect, currentUser.vendor]
  );

  const handleSetBookmark = useCallback(() => {
    localStorage.setItem('cachedBookmark', location.pathname);
  }, [location]);

  const handleGoToBookmark = useCallback(
    (event: KeyboardEvent) => {
      const bookmark = localStorage.getItem('cachedBookmark');
      if (!bookmark) {
        return;
      }

      openRedirect(event, bookmark);
    },
    [openRedirect]
  );

  const handleVendorSearchShortcut = useCallback(() => {
    localStorage.setItem('previousVendor', currentUser.vendor.name);
    dispatch(setUserMenuOpen(true));
  }, [currentUser.vendor, dispatch]);

  const handleDevelopmentMode = useCallback(() => {
    const newValue = !currentUser.isDevelopmentMode;
    dispatch(CurrentUserActions.setDevelopmentMode(newValue));

    showAlert(
      newValue
        ? 'Development mode on. Additional settings are available in the UI.'
        : 'Development mode deactivated.'
    );
  }, [currentUser.isDevelopmentMode, dispatch]);

  const handleGoToAlloyPublic = useCallback(
    (event: KeyboardEvent) => {
      openRedirect(event, '/ALLOY_PUBLIC');
    },
    [openRedirect]
  );

  const handleGoToAlloyPublicJobs = useCallback(
    (event: KeyboardEvent) => {
      openRedirect(event, '/ALLOY_PUBLIC/settings/jobs');
    },
    [openRedirect]
  );

  const handleGoToAlloyPublicJobLocks = useCallback(
    (event: KeyboardEvent) => {
      openRedirect(event, '/ALLOY_PUBLIC/settings/job-locks');
    },
    [openRedirect]
  );

  const handleGoToAlloyPublicFiletypes = useCallback(
    (event: KeyboardEvent) => {
      openRedirect(event, '/ALLOY_PUBLIC/settings/filetypes');
    },
    [openRedirect]
  );

  const handleGoToLocal = useCallback(() => openNewEnv('alloy.test:3000'), [openNewEnv]);

  const handleGoToStaging = useCallback(() => openNewEnv('staging.alloymetrics.com'), [openNewEnv]);

  const handleGoToProduction = useCallback(() => openNewEnv('app.alloy.ai'), [openNewEnv]);

  const handleImpersonateUser = useCallback(
    () => changePermission(Types.Role.USER, currentUser, dispatch),
    [dispatch, currentUser]
  );

  const handleImpersonateAdmin = useCallback(
    () => changePermission(Types.Role.ADMIN, currentUser, dispatch),
    [dispatch, currentUser]
  );

  const handleImpersonateSuperAdmin = useCallback(
    () => changePermission(Types.Role.SUPER_ADMIN, currentUser, dispatch),
    [dispatch, currentUser]
  );

  const handlePresentationMode = useCallback(
    () => dispatch(activatePresentationMode()),
    [dispatch]
  );

  const handleToggleCacheInspector = useCallback(
    () => dispatch(toggleCacheInspectorVisible()),
    [dispatch]
  );

  const handleManualUpdateMode = useCallback(() => {
    showAlert(
      `Manual update mode ${currentUser.isManualViewUpdateMode ? 'deactivated' : 'activated'}.`
    );
    dispatch(CurrentUserActions.setManualViewUpdateMode(!currentUser.isManualViewUpdateMode));
  }, [dispatch, currentUser.isManualViewUpdateMode]);

  const handleGoToFeatures = useCallback(() => {
    dispatch(CurrentUserActions.setPermissionsOverrideDialogOpen(true));
  }, [dispatch]);

  const handleToggleComputeCache = useCallback(() => {
    const minutes = 5;
    const enabled = toggleComputeCacheGlobally(minutes * 60);
    showAlert(`Compute cache ${enabled ? 'enabled.' : `disabled for ${minutes} minutes.`}`);
  }, []);

  const openShortcutHelp = useCallback(() => setIsShortcutHelpOpen(true), []);
  const closeShortcutHelp = useCallback(() => setIsShortcutHelpOpen(false), []);

  const handleToggleSidebar = useCallback(() => {
    dispatch(CurrentUserActions.setSettingsSidebarOpen(!currentUser.isSettingsSidebarOpen));
  }, [dispatch, currentUser.isSettingsSidebarOpen]);

  const activeVendors = allowedVendors
    .filter(vendor => vendor.active)
    .sortBy(vendor => vendor.displayName);
  const currentVendorIndex = activeVendors.findIndex(vendor => vendor.id === currentUser.vendor.id);

  const handleGoToPreviousVendor = () => {
    if (currentVendorIndex === 0) {
      return;
    }

    const prevVendor = assertTruthy(activeVendors.get(currentVendorIndex - 1));
    window.location.pathname = getNewVendorPathname(currentUser, prevVendor);
  };

  const handleGoToNextVendor = () => {
    if (currentVendorIndex === activeVendors.size - 1) {
      return;
    }

    const nextVendor = assertTruthy(activeVendors.get(currentVendorIndex + 1));
    window.location.pathname = getNewVendorPathname(currentUser, nextVendor);
  };

  const defaultAttributeHierarchies = useSelector(
    state => state.analysis.data.defaultAttributeHierarchies
  );
  const availableMetrics = useSelector(getAllMetricsWithAnyData);
  const allGroupings = useSelector(state => state.analysis.data.allGroupings);
  const defaultAttributes = useResult(
    useApi(Api.Attributes.getDefaultFilterAttributes.getResource())
  );

  const handleCreateDashboard = () =>
    dispatch(
      storeAndGoToNewView(
        createDefaultView(
          currentUser,
          currentUser.settings.analysisSettings,
          defaultAttributeHierarchies,
          availableMetrics,
          allGroupings,
          defaultAttributes
        )
      )
    );

  const handlers: GlobalHotKeyHandler = {
    handleCreateDashboard,
    handleDataForwardedFilesShortcut,
    handleDataSubscriptionsShortcut,
    handleDataImportShortcut,
    handleDataLocationsShortcut,
    handleDataProductsShortcut,
    handleDataStatusShortcut,
    handleDevelopmentMode,
    handleFeedsShortcut,
    handleFilesShortcut,
    handleGlobalCreateShortcut,
    handleGoToAlloyPublic,
    handleGoToAlloyPublicFiletypes,
    handleGoToAlloyPublicJobLocks,
    handleGoToAlloyPublicJobs,
    handleGoToBookmark,
    handleGoToFeatures,
    handleGoToHomepage,
    handleGoToLocal,
    handleGoToNextVendor,
    handleGoToPreviousVendor,
    handleGoToProduction,
    handleGoToStaging,
    handleImpersonateAdmin,
    handleImpersonateSuperAdmin,
    handleImpersonateUser,
    handleManualUpdateMode,
    handleMetricSnapshotsShortcut,
    handlePresentationMode,
    handleToggleCacheInspector,
    handlePreviousVendorShortcut,
    handleSearchShortcut,
    handleSetBookmark,
    handleSettingsShortcut,
    handleSettingsFilesShortcut,
    handleToggleComputeCache,
    handleVendorSearchShortcut,
    openShortcutHelp,
    handleToggleSidebar,
  };

  const hotKeyDefinitions = getGlobalHotKeyDefinitions(currentUser, allowedVendors, handlers);

  return (
    <>
      <ModalDialog
        cancelActionName="Close"
        className="global-hotkeys-shortcut-help-dialog"
        isConfirmVisible={false}
        isOpen={isShortcutHelpOpen}
        title="Keyboard Shortcuts"
        onClose={closeShortcutHelp}
      >
        <ShortcutHelp />
      </ModalDialog>
      <HotKeys definitions={hotKeyDefinitions} />
    </>
  );
};

type Props = {
  location: History.Location;
  onFocusSearchRequested: () => void;
};

export default GlobalHotKeys;
